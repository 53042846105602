// export const environment = {
//   production: false,
//   authHost: "https://127.0.0.1:8000/auth/",
//   apiHost: "http://127.0.0.1:8000/",
//   appMode: "central" //camera, central or offline
// };
export const environment = {
  production: true,
  authHost: "https://modivo-gatecontroller.anteeo.com.pl/api/auth/",
  apiHost: "https://modivo-gatecontroller.anteeo.com.pl/api/",
  appMode: "central" //camera, central or offline
};
