import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Subscription } from 'rxjs';
import { TokenStorageService } from './services/token.service';
import { EventBusService } from './shared/event-bus.service';
import { EventData } from './shared/event.class';
import { ModalController } from '@ionic/angular';
import { NoAccessPopupComponent } from 'src/app/components/no-access-popup/no-access-popup.component'
import { ROLES_LIST } from './shared/global-roles'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  private roles: string[] = [];
  private isLoggedIn = false;
  public menuItems;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  eventBusSub?: Subscription;

  constructor(
    private router: Router,
    private storage: Storage,
    private tokenStorageService: TokenStorageService,
    private eventBusService: EventBusService,
    private modalController: ModalController) {
    this.menuItems = [
      {
        title: 'Strona domowa',
        path: '/home'
      },
      {
        title: 'Auta',
        path: '/cars'
      },
      {
        title: 'Awizacje',
        path: '/permits'
      },
      {
        title: 'Bramy',
        path: '/gates'
      },
      {
        title: 'Kamery',
        path: '/cameras'
      },
      {
        title: 'Goście',
        path: '/guests'
      },
      {
        title: 'Firmy',
        path: '/companies'
      },
      {
        title: 'Ustawienia',
        path: '/settings'
      },
      {
        title: 'Raporty',
        path: '/reports/conditions'
      },
    ];
  }

  public protectedMenuItems(isAdminRoute: boolean, path?: string): any[] {
    let items = [];
    if (isAdminRoute || path == "reports") {
      items = [
        {
          title: "Strona domowa",
          path: "/home"
        }
      ];
    } else {
      const role = this.tokenStorageService.getMaxRole()
      switch (role) {
        case ROLES_LIST.Organizer: {
          items = this.menuItems.filter(i => i.title !== 'Raporty')
          break
        }
        case ROLES_LIST.Security: {
          items = []
          break
        }
        case ROLES_LIST.User: {
          items = [
            {
              title: 'Strona domowa',
              path: '/home'
            },
            {
              title: 'Ustawienia',
              path: '/settings'
            },
          ]
          break
        }
        default: {
          items = this.menuItems
          break
        }
      }
    }
    if (this.tokenStorageService.getToken()) {
      if (this.tokenStorageService.isAdmin()) {
      }
      if (this.tokenStorageService.isSuperAdmin()) {
      }
      if ((this.tokenStorageService.isAdmin() || this.tokenStorageService.isSuperAdmin())) {
        if (!isAdminRoute && path !== "reports") {
          items = items.concat([
            {
              title: 'Panel Administracyjny',
              path: '/admin/users'
            },
          ])
        } else {
          if (path == "admin") {
            items = items.concat([
              {
                title: 'Użytkownicy',
                path: '/admin/users'
              },
              {
                title: 'Szablony maili',
                path: '/admin/templates'
              },
              {
                title: 'Konfiguracja mailingu',
                path: '/admin/config'
              }
            ])
          } else if (path == "reports") {
            items = items.concat([
              {
                title: 'Zgody',
                path: '/reports/conditions'
              },
              {
                title: 'Maile',
                path: '/reports/emails'
              }
            ])
          }
        }
      }
      return items
    }
  }

  async ngOnInit() {
    await this.storage.create();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
      this.router.navigate(["/login"]);
    });
    this.eventBusSub.add(
      this.eventBusService.on('no_access', () => {
        this.showNoAccessPopup();
      })
    )
    if (this.isLoggedIn) {
      const user = await this.tokenStorageService.getUser();
      this.username = user.username;
    } else {
      // nie wiem ale naprawia xD
      await this.router.events.toPromise();
      this.eventBusService.emit(new EventData('logout', null))
    }
  }

  async showNoAccessPopup() {
    const modal = await this.modalController.create({
      component: NoAccessPopupComponent,
      swipeToClose: true
    });
    return await modal.present();
  }

  isLogged() {
    return this.isLoggedIn;
  }

  changeLoggedIn(isLogg: boolean) {
    this.isLoggedIn = isLogg;
  }

  ngOnDestroy(): void {
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.isLoggedIn = false;
    this.roles = [];
    this.showAdminBoard = false;
    this.showModeratorBoard = false;
  }
}
