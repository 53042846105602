import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import jwt_decode from "jwt-decode";
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventBusService } from '../shared/event-bus.service';
import { EventData } from '../shared/event.class';
import { ROLES_LIST, UserJWT } from '../shared/global-roles';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class TokenStorageService {

  private _storage: Storage | null = null;
  // private subscription: Subscription;
  public token: string;

  constructor(
    private eventBusService: EventBusService,
    private storage: Storage,
    private authService: AuthService) {
    this.init();
  }

  async ngOnInit() {
    const refreshToken: string = this.getRefreshToken();
    // this.subscription = this.authService.refreshToken(refreshToken).subscribe((res) => {
    //     console.log(res);
    //     this.token = res.token;
    //     this.saveToken(this.token);
    // });
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  signOut(): void {
    // this._storage.remove("token");
    // this._storage.remove("refreshToken");
    // this._storage.remove("user")
    // this._storage.clear()
    window.sessionStorage.clear();
    const refreshToken = this.getRefreshToken()
    this.authService.logout(refreshToken);
  }

  public saveToken(token: string) {
    // this._storage.set('token', token);
    window.sessionStorage.removeItem('token');
    window.sessionStorage.setItem('token', token);
    const user = this.getUser();
    // if (user.id) {
    //     this.saveUser({ ...user, token: token });
    // }
  }

  public getToken(): string {
    return window.sessionStorage.getItem('token');
    // return this._storage.get("token").then((token) => {
    //     return token;
    // })
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem('refreshToken');
    window.sessionStorage.setItem('refreshToken', token);
    // this._storage.set('refreshToken', token)
  }

  public getRefreshToken(): string {
    return window.sessionStorage.getItem('refreshToken');
    // return this._storage.get('refreshToken').then((refreshToken) => {
    //     return refreshToken;
    // })
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem('user');
    window.sessionStorage.setItem('user', JSON.stringify(user));
    // this._storage.set('user', user);
  }

  public getUser(): any {
    return window.sessionStorage.getItem('user');
    // const user = await this._storage.get('user')
    // if (user) {
    //     return user;
    // }
    // return {};
  }

  public getMaxRoleFromToken(token: string): number {
    const roles = jwt_decode<UserJWT>(token).roles
    if (roles.includes(ROLES_LIST.SuperAdmin)) return ROLES_LIST.SuperAdmin
    if (roles.includes(ROLES_LIST.Admin)) return ROLES_LIST.Admin
    if (roles.includes(ROLES_LIST.Manager)) return ROLES_LIST.Manager
    if (roles.includes(ROLES_LIST.Organizer)) return ROLES_LIST.Organizer
    if (roles.includes(ROLES_LIST.Security)) return ROLES_LIST.Security
    return ROLES_LIST.User
  }

  public isAdmin(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.Admin)
  }

  public isSuperAdmin(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.SuperAdmin)
  }

  public isUser(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.User)
  }

  public isManager(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.Manager)
  }

  public isOrganizer(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.Organizer)
  }

  public isSecurity(): boolean {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token')).roles.includes(ROLES_LIST.Security)
  }

  public getJWT(): UserJWT {
    return jwt_decode<UserJWT>(window.sessionStorage.getItem('token'))
  }

  public getMaxRole(): number {
    if (this.isSuperAdmin()) return ROLES_LIST.SuperAdmin
    if (this.isAdmin()) return ROLES_LIST.Admin
    if (this.isManager()) return ROLES_LIST.Manager
    if (this.isOrganizer()) return ROLES_LIST.Organizer
    if (this.isSecurity()) return ROLES_LIST.Security
    return ROLES_LIST.User
  }
  // public async checkToken(): Promise<any> {
  //     const token: string = this.getToken();
  //     // return this.getToken().then((tok) => {
  //     //     token = tok;
  //     //     return this.getRefreshToken();
  //     // }).then((refreshToken) => {
  //         const refreshToken = this.getRefreshToken();
  //         console.log("Token", token)
  //         if (token) {
  //             const decodedToken: any = jwt_decode(token);
  //             console.log("decodedToken", decodedToken)
  //             if (decodedToken.exp >= Date.now() / 1000) {
  //                 let newToken;
  //                 this.authService.refreshToken(refreshToken)
  //                 return this.subscription = this.authService.refreshToken(refreshToken).pipe(take(1)).subscribe((res) => {
  //                     console.log("!!! RES:", res);
  //                     newToken = res.token;
  //                     console.log("!!! New: ", newToken);
  //                     this.saveToken(newToken)
  //                     // return this.saveToken(newToken).then(() =>{
  //                     //     console.log("newToken saved")
  //                     // })
  //                     return newToken;
  //                 });
  //                 // this.authService.refreshToken(refreshToken).pipe(take(1)).subscribe(([newTokenVal]) => {
  //                 //     console.log("!!!! co nowego: ", newTokenVal);
  //                 //     newToken = newTokenVal.token
  //                 // })
  //             }
  //             return this.getToken();
  //         }
  //         console.log("!!!PROBLEM");
  //         this.eventBusService.emit(new EventData('logout', null));
  //         return null;
  //     // }).catch((error) => {
  //     //     console.log("!!!", error);
  //     //     this.eventBusService.emit(new EventData('logout', null));
  //     //     return null;
  //     // });
  //     // console.log("!!!ERROROs")
  //     // return null;
  // }

  ngOnDestroy() {
    // if (this.subscription) {
    //     this.subscription.unsubscribe();
    // }
  }
}
