import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token.service';
import { ROLES_LIST } from '../shared/global-roles';

@Injectable({
  providedIn: 'root'
})

export class MinimumOrganizerGuard implements CanActivate {
  constructor(
    private tokenService: TokenStorageService,
    private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.tokenService.getMaxRole()
    if (role !== ROLES_LIST.User && role !== ROLES_LIST.Security) {
      return true;
    } else {
      this.router.navigate(['/home'])
      return false;
    }
  }

}
