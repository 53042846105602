import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ToastController } from '@ionic/angular';
import { EventBusService } from 'src/app/shared/event-bus.service';
import { EventData } from 'src/app/shared/event.class';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private toastController: ToastController,
    private eventBusService: EventBusService
  ) { }

  async presentToast(message: string, time: number = 2000) {
    const toast = await this.toastController.create({
      message: message,
      duration: time
    });
    toast.present();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('This is client side error');
            errorMsg = `Błąd po stronie klienta. ${error.error.message}`;
          } else {
            switch (error.status) {
              case 401:
                this.eventBusService.emit(new EventData('logout', null))
                return
              case 403:
                this.eventBusService.emit(new EventData('no_access', null))
                return
              default:
                console.log('This is server side error: ', error);
                errorMsg = `Błąd po stronie serwera. Error Code: ${error.status} Error message: ${error.message}`;
                if (error?.error?.error) {
                  errorMsg = `Błąd po stronie serwera. Error Code: ${error.status} Error message: ${error.error.error}`;
                }
            }
            this.presentToast(errorMsg, 5000)
            return throwError(errorMsg);
          }
        })
      )
  }
}

export const errorInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true }
];
