import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAccessPopupComponent } from 'src/app/components/no-access-popup/no-access-popup.component'
import { AdminSuperAdminGuard } from 'src/app/helpers/admin-super-admin.guard';
import { MinimumManagerGuard } from 'src/app/helpers/minimum-manager.guard';
import { MinimumOrganizerGuard } from 'src/app/helpers/minimum-organizer.guard';
import { LoginGuard } from 'src/app/helpers/login.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'oauth/microsoft',
    loadChildren: () => import('./pages/oauth2-microsoft-validation/oauth2-microsoft-validation.module').then(m => m.Oauth2MicrosoftValidationPageModule)
    // redirectTo: '/login',
    // pathMatch: 'full'
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  // },
  // {
  //   path: 'permits',
  //   loadChildren: () => import('./pages/permits/permits.module').then(m => m.PermitsPageModule),
  //   canActivate: [MinimumOrganizerGuard]
  // },
  // {
  //   path: 'cars',
  //   loadChildren: () => import('./pages/cars/cars.module').then(m => m.CarsPageModule),
  //   canActivate: [MinimumOrganizerGuard]
  // },
  {
    path: 'add-car',
    loadChildren: () => import('./pages/cars/add/add-car.module').then(m => m.AddCarPageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  {
    path: 'edit-car/:id',
    loadChildren: () => import('./pages/cars/edit/edit-car.module').then(m => m.EditCarPageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  // {
  //   path: 'guests',
  //   loadChildren: () => import('./pages/guests/guests.module').then(m => m.GuestsPageModule),
  //   canActivate: [MinimumOrganizerGuard]
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  // },
  {
    path: 'car-info/:id',
    loadChildren: () => import('./pages/cars/info/car-info.module').then(m => m.CarInfoPageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  // {
  //   path: 'gates',
  //   loadChildren: () => import('./pages/gates/gates.module').then(m => m.GatesPageModule),
  //   canActivate: [MinimumOrganizerGuard]
  // },
  {
    path: 'add-gate',
    loadChildren: () => import('./pages/gates/add/add-gate.module').then(m => m.AddGatePageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  {
    path: 'edit-gate/:id',
    loadChildren: () => import('./pages/gates/edit/edit-gate.module').then(m => m.EditGatePageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  // {
  //   path: 'cameras',
  //   loadChildren: () => import('./pages/cameras/cameras.module').then(m => m.CamerasPageModule),
  //   canActivate: [MinimumOrganizerGuard]
  // },
  {
    path: 'add-camera',
    loadChildren: () => import('./pages/cameras/add/add-camera.module').then(m => m.AddCameraPageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  {
    path: 'edit-camera/:id',
    loadChildren: () => import('./pages/cameras/edit/edit-camera.module').then(m => m.EditCameraPageModule),
    canActivate: [MinimumOrganizerGuard]
  },
  {
    path: 'no-access', component: NoAccessPopupComponent
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/admin/config/config.module').then(m => m.ConfigPageModule),
  },
  {
    path: 'add-camera',
    loadChildren: () => import('./pages/cameras/add/add-camera.module').then(m => m.AddCameraPageModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
