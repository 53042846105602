import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-no-access-popup',
  template: `<ion-content>
                <div class="popup-container">
                  <h3>Nie masz dostępu do tej strony</h3>
                  <button ion-button (click)="dismiss()">OK</button>
                </div>
              </ion-content>`,
  styleUrls: ['./no-access-popup.component.scss'],
})
export class NoAccessPopupComponent {
  constructor(private modalController: ModalController) { }

  dismiss() {
    this.modalController.dismiss();
  }
}
